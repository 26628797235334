import CustomElementComponentComponent from '@wix/thunderbolt-elements/src/components/CustomElement/CustomElementComponent/viewer/CustomElementComponent';


const CustomElementComponent = {
  component: CustomElementComponentComponent
};


export const components = {
  ['CustomElementComponent']: CustomElementComponent
};


// temporary export
export const version = "1.0.0"
